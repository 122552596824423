import { Alert, Button, Card, Form, Modal, Row, Skeleton, Switch, Tag } from 'antd';

import FormSelection from 'components/FormSelection/FormSelection';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import styles from './AirbnbHotelForm.module.css';

import { notification } from 'antd';
import { getConstants } from 'utils/apis/constants';
import { getAirbnbListings, getAirbnbs, getRoomTypeIntegrations, linkExistingAirbnb } from 'utils/apis/integration';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { getUnits } from 'utils/apis/unit';
import { generatePercentageFromDisplay } from 'utils/general';
import AirbnbAdvanceOptionModal from '../../../OTAListingForm/components/Airbnb/components/AirbnbAdvanceOptionModal';
import OTARateInput from '../../../OTARateInput/OTARateInput';

const STANDARD_RATE_CODE = 'STD';

// Check if a room type connection exists for a master listing
const checkAirbnbHotelRoomConnection = async (propertyId, roomTypeId) => {
  try {
    const response = await getRoomTypeIntegrations(propertyId);
    if (response && response.data) {
      // Find any integration for this listing
      const existingIntegration = response.data.find(integration => integration.roomTypeId === roomTypeId);
      // Find any integration with a different room type
      const conflictingIntegration = response.data.find(integration => integration.roomTypeId !== roomTypeId);

      return {
        exists: !!existingIntegration || !!conflictingIntegration,
        isEdit: !!existingIntegration,
        hasConflict: !!conflictingIntegration
      };
    }
    return { exists: false, isEdit: false, hasConflict: false };
  } catch (err) {
    return { exists: false, isEdit: false, hasConflict: false };
  }
};

// ----------------------------------------------------------------------
// AirbnbHotelForm Component – new hotel-style integration form for Airbnb listings
// ----------------------------------------------------------------------
class AirbnbHotelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomMapping: [],
      airbnbListings: [],
      isSaving: false,
      isLoading: true,
      airbnbUsers: [],
      rateModifierTypeConstants: [],
      rateModifierSignConstants: [],
      currency: 'MYR',
      errors: {},
      availableUnits: [],
      selectedMasterUnit: null
    };
  }

  async componentDidMount() {
    const { property, data } = this.props;

    try {
      this.setState({ isLoading: true });

      // Load constants for rate modifiers
      const [rateModifierTypeConstants, rateModifierSignConstants] = await Promise.all([
        getConstants('rateModifierType').then(res => Object.values(res.data)),
        getConstants('rateModifierSign').then(res => Object.values(res.data))
      ]);

      this.setState({
        rateModifierTypeConstants,
        rateModifierSignConstants,
        currency: data && data.propertyCurrency ? data.propertyCurrency : 'MYR'
      });

      // Get available units for this property
      const units = property && (await getUnits(property._id));
      this.setState({ availableUnits: units || [] });

      // Continue with normal initialization
      await this.initializeForm();

      // If we're in edit mode, set the initial state from the existing integration
      if (data && data.roomTypes) {
        const { roomTypes = [] } = data;

        // Create initial room mappings with all necessary fields
        const initialRoomMapping = roomTypes.map(rt => ({
          roomType: rt.roomType._id,
          airbnbListingId: rt.airbnbListingId,
          masterUnitId: rt.masterUnitId,
          connectionExists: true,
          rate: rt.rate || {
            weekday: 0,
            weekend: 0,
            isDerived: false
          },
          advanceOptions: rt.advanceOptions || {},
          isAdvanceOptionModalVisible: false
        }));

        this.setState({
          roomMapping: initialRoomMapping,
          initialData: data // Store initial data for use after render
        });
      }

      // Load rate object last, after we have all necessary data
      await this.loadRateObject();
    } catch (error) {
      Modal.error({
        title: intl.get('integration.error.title').d('Error'),
        content: intl.get('integration.error.initialization').d('Failed to initialize the form.')
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.props;
    const { initialData, isLoading, airbnbListings, airbnbUsers } = this.state;

    // Only set form values after loading is complete, we have initial data, and airbnb data is loaded
    if (prevState.isLoading && !isLoading && initialData && airbnbListings.length > 0 && airbnbUsers.length > 0) {
      const { roomTypes = [] } = initialData;

      // Set form fields for each room type and update roomMapping with airbnbId
      roomTypes.forEach(rt => {
        // Find the Airbnb listing and get its host ID
        const listing = airbnbListings.find(l => l._id === rt.airbnbListingId);
        const airbnbId = listing ? listing.airbnb : null;

        form.setFieldsValue({
          [`masterUnit_${rt.roomType._id}`]: rt.masterUnitId,
          [`airbnbId_${rt.roomType._id}`]: airbnbId,
          [`airbnbListingId_${rt.roomType._id}`]: listing ? listing._id : undefined
        });

        // Update the roomMapping with the airbnbId
        this.setState(prevState => ({
          roomMapping: prevState.roomMapping.map(mapping =>
            mapping.roomType === rt.roomType._id
              ? {
                  ...mapping,
                  masterUnitId: rt.masterUnitId,
                  airbnbId,
                  airbnbListingId: listing ? listing._id : null
                }
              : mapping
          )
        }));
      });

      // Clear initialData after setting form values
      this.setState({ initialData: null });
    }
  }

  initializeForm = async () => {
    try {
      const { propertyHostId, data } = this.props;
      const [airbnbUsers, airbnbListings] = await Promise.all([getAirbnbs({ host: propertyHostId }), getAirbnbListings({ hosts: propertyHostId })]);

      // Store the fetched data
      this.setState({
        airbnbUsers: airbnbUsers.data || [],
        airbnbListings: airbnbListings.data || []
      });

      // If we have initial data, set the Airbnb host ID first
      if (data && data.airbnbId) {
        const { form } = this.props;
        form.setFieldsValue({ airbnbId: data.airbnbId });
      }
    } catch (error) {
      Modal.error({
        title: intl.get('integration.error.title').d('Error'),
        content: intl.get('integration.error.initialization').d('Failed to initialize the form.')
      });
    }
  };

  loadRateObject = async () => {
    const { listingIntegration } = this.props;

    try {
      if (listingIntegration && listingIntegration.rate) {
        const { rate } = listingIntegration;
        // Update all room mappings with the same rate initially
        this.setState(prevState => ({
          roomMapping: prevState.roomMapping.map(mapping => ({
            ...mapping,
            rate
          }))
        }));
      } else {
        await this.getWebRate();
      }
    } catch (error) {
      // Set a default rate object if we can't load one
      this.setState(prevState => ({
        roomMapping: prevState.roomMapping.map(mapping => ({
          ...mapping,
          rate: {
            weekday: 0,
            weekend: 0,
            isDerived: false
          }
        }))
      }));
    }
  };

  getWebRate = async () => {
    const { property } = this.props;
    const { roomMapping } = this.state;

    // Guard against missing property or roomTypes
    if (!property || !property.roomTypes || !property.roomTypes.length) {
      return;
    }

    try {
      // Get web rates for each room type in the mapping
      const updatedRoomMapping = await Promise.all(
        roomMapping.map(async mapping => {
          try {
            const webRates = await getWebRateByRoomType(mapping.roomType);
            const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);

            if (stdRate) {
              const { weekday, weekend, isDerived } = stdRate;
              return {
                ...mapping,
                rate: { weekday, weekend, isDerived }
              };
            }
            return {
              ...mapping,
              rate: { weekday: 0, weekend: 0, isDerived: false }
            };
          } catch (error) {
            return {
              ...mapping,
              rate: { weekday: 0, weekend: 0, isDerived: false }
            };
          }
        })
      );

      this.setState({ roomMapping: updatedRoomMapping });
    } catch (error) {
      this.setState(prevState => ({
        roomMapping: prevState.roomMapping.map(mapping => ({
          ...mapping,
          rate: { weekday: 0, weekend: 0, isDerived: false }
        }))
      }));
    }
  };

  updateRate = roomTypeId => newRates => {
    this.setState(prevState => ({
      roomMapping: prevState.roomMapping.map(mapping =>
        mapping.roomType === roomTypeId ? { ...mapping, rate: { ...mapping.rate, ...newRates } } : mapping
      )
    }));
  };

  generateAirbnbUserOptions = () => {
    const { airbnbUsers } = this.state;
    return airbnbUsers.map(airbnbUser => ({
      value: airbnbUser._id,
      displayValue: `${airbnbUser.userId} | ${airbnbUser.nickname || intl.get('hostConnect.integration.message.nickname').d('Nickname Not Set')}`,
      key: airbnbUser._id
    }));
  };

  generateAirbnbListingOptions = airbnbId => {
    const { airbnbListings } = this.state;
    if (!airbnbId) return [];

    return airbnbListings
      .filter(listing => listing.airbnb === airbnbId)
      .map(listing => ({
        value: listing._id,
        displayValue: `${listing.listingId} | ${(listing.data && listing.data.listing && listing.data.listing.name) ||
          intl.get('hostConnect.integration.message.noListing').d('No Listing Name')}`,
        key: listing._id
      }));
  };

  /**
   * Toggle whether to sync a room type.
   * If toggled on, add a mapping record with a null master listing.
   * If toggled off, remove the mapping record.
   */
  handleOnToggleRoomSync = roomTypeId => checked => {
    this.setState(
      prevState => {
        let newRoomMapping;
        if (checked) {
          // Check if mapping already exists
          const existingMapping = prevState.roomMapping.find(m => m.roomType._id === roomTypeId);

          if (!existingMapping) {
            // Add new mapping
            newRoomMapping = [
              ...prevState.roomMapping,
              {
                roomType: roomTypeId,
                masterUnitId: null,
                airbnbId: null,
                airbnbListingId: null,
                connectionExists: false,
                rate: {
                  weekday: 0,
                  weekend: 0,
                  isDerived: false
                },
                advanceOptions: {},
                isAdvanceOptionModalVisible: false
              }
            ];
          } else {
            newRoomMapping = prevState.roomMapping;
          }
        } else {
          // Remove mapping
          newRoomMapping = prevState.roomMapping.filter(m => m.roomType !== roomTypeId);
        }

        return { roomMapping: newRoomMapping };
      },
      () => {
        if (checked) {
          this.getWebRate();
        }
      }
    );
  };

  /**
   * Handle selection change in the searchable dropdown for the Airbnb master listing.
   * After selecting a master listing, we call the check for an already existing room type connection.
   */
  handleOnSelectAirbnbForRoom = roomTypeId => selectedListingId => {
    let { roomMapping } = this.state;
    const { property } = this.props;
    const mappingIndex = roomMapping.findIndex(m => m.roomType === roomTypeId);
    if (mappingIndex !== -1) {
      roomMapping[mappingIndex].airbnbListingId = selectedListingId;
      checkAirbnbHotelRoomConnection(property._id, roomTypeId)
        .then(res => {
          roomMapping[mappingIndex] = {
            ...roomMapping[mappingIndex],
            connectionExists: res.exists,
            isEdit: res.isEdit,
            hasConflict: res.hasConflict
          };
          this.setState({ roomMapping });
        })
        .catch(err => {
          console.error('Failed to check room type connection:', err);
        });
    }
  };

  validateForm = () => {
    const { roomMapping, errors } = this.state;
    const newErrors = {};

    if (roomMapping.length === 0) {
      newErrors.roomMapping = intl.get('integration.error.noRoomTypes').d('Please select at least one room type to sync');
    }

    // Validate each enabled room type has all required fields
    roomMapping.forEach(mapping => {
      if (!mapping.masterUnitId) {
        newErrors[`masterUnit_${mapping.roomType}`] = intl.get('integration.error.requiredMasterUnit').d('Please select a master unit');
      }
      if (!mapping.airbnbId) {
        newErrors[`airbnbId_${mapping.roomType}`] = intl.get('integration.error.requiredAirbnbId').d('Please select an Airbnb ID');
      }
      if (!mapping.airbnbListingId) {
        newErrors[`airbnbListingId_${mapping.roomType}`] = intl.get('integration.error.requiredListing').d('Please select an Airbnb listing');
      }
    });

    this.setState({ errors: { ...errors, ...newErrors } });
    return Object.keys(newErrors).length === 0;
  };

  handleMasterUnitChange = (roomTypeId, unitId) => {
    let { roomMapping } = this.state;
    const mappingIndex = roomMapping.findIndex(m => m.roomType === roomTypeId);

    if (mappingIndex !== -1) {
      roomMapping[mappingIndex].masterUnitId = unitId;
      this.setState({ roomMapping });
    }
  };

  handleListingChange = (roomTypeId, listingId) => {
    let { roomMapping } = this.state;
    const { property } = this.props;
    const mappingIndex = roomMapping.findIndex(m => m.roomType === roomTypeId);

    if (mappingIndex !== -1) {
      roomMapping[mappingIndex].airbnbListingId = listingId;

      // Check for existing connections
      checkAirbnbHotelRoomConnection(property._id, roomTypeId)
        .then(res => {
          roomMapping[mappingIndex] = {
            ...roomMapping[mappingIndex],
            connectionExists: res.exists,
            isEdit: res.isEdit,
            hasConflict: res.hasConflict
          };
          this.setState({ roomMapping });
        })
        .catch(err => {
          console.error('Failed to check room type connection:', err);
        });
    }
  };

  handleAdvanceOptionsClick = roomTypeId => () => {
    const { property } = this.props;
    const { roomMapping } = this.state;

    const mapping = roomMapping.find(m => m.roomType === roomTypeId);
    if (!mapping || !mapping.masterUnitId || !mapping.airbnbListingId) {
      return;
    }

    const roomTypeObj = property.roomTypes.find(rt => rt._id === roomTypeId);
    if (roomTypeObj && roomTypeObj._id) {
      this.setState(prevState => ({
        roomMapping: prevState.roomMapping.map(m => (m.roomType === roomTypeId ? { ...m, isAdvanceOptionModalVisible: true } : m))
      }));
    }
  };

  handleAdvanceOptionsClose = roomTypeId => () => {
    this.setState(prevState => ({
      roomMapping: prevState.roomMapping.map(m => (m.roomType === roomTypeId ? { ...m, isAdvanceOptionModalVisible: false } : m))
    }));
  };

  handleAdvanceOptionsSubmit = roomTypeId => advanceOptions => {
    // Process rate distribution payload like in AirbnbForm
    const processedRate = this.massageProcessedRate(advanceOptions.rateDistributionPayload);

    this.setState(prevState => ({
      roomMapping: prevState.roomMapping.map(m =>
        m.roomType === roomTypeId
          ? {
              ...m,
              advanceOptions,
              isAdvanceOptionModalVisible: false,
              rate: processedRate
            }
          : m
      )
    }));
  };

  massageProcessedRate = rateDistributionPayload => {
    const { rate, rateModifierSignConstants, rateModifierTypeConstants } = this.state;

    let processedRate = {};
    const rateObject = rateDistributionPayload.length > 0 && rateDistributionPayload[0].rates.length > 0 && rateDistributionPayload[0].rates[0];
    if (!!rateObject) {
      processedRate = {
        weekday: rateObject.rate.weekdayRate,
        weekend: rateObject.rate.weekendRate,
        isDerived: rateObject.rate.isDerived
      };
      const isDerived = rateObject.rate.isDerived;
      if (!!isDerived) {
        const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
        const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
        let amount = rateObject.rate.modifierAmount;

        amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

        processedRate.calculation = {
          amount,
          type: rateObject.rate.modifierType,
          isPositive
        };
      }
    } else {
      processedRate = rate;
    }

    return processedRate;
  };

  onConfirm = async () => {
    if (!this.validateForm()) {
      return;
    }

    const { roomMapping } = this.state;
    const { onAfterSave } = this.props;

    this.setState({ isSaving: true });

    try {
      // Create room type integrations using linkExistingAirbnb
      await Promise.all(
        roomMapping.map(async mapping => {
          return linkExistingAirbnb(
            null, // unitId is null for room type integration
            mapping.airbnbListingId, // Use the room-specific listing ID
            'sync_rates_and_availability',
            mapping.rate,
            {
              ...mapping.advanceOptions,
              isRoomTypeInventory: true,
              roomTypeId: mapping.roomType,
              totalInventoryCount: await this.getRoomTypeInventoryCount(mapping.roomType),
              masterUnitId: mapping.masterUnitId
            }
          );
        })
      );

      if (onAfterSave) {
        onAfterSave();
      }

      this.setState({ isSaving: false });
      notification.success({
        message: intl.get('integration.success.roomTypeSync').d('Room type integration created successfully')
      });
    } catch (error) {
      this.setState({ isSaving: false });
      notification.error({
        message: intl.get('integration.error.title').d('Error'),
        description: intl.get('integration.error.save').d('Failed to save room type integrations.')
      });
    }
  };

  getRoomTypeInventoryCount = async roomTypeId => {
    try {
      const units = await getUnits(this.props.property._id, { roomType: roomTypeId });
      return units.length;
    } catch (error) {
      return 0;
    }
  };

  handleAirbnbHostChange = roomTypeId => value => {
    const { form } = this.props;

    // Clear the Airbnb listing selection for this room
    form.setFieldsValue({
      [`airbnbListingId_${roomTypeId}`]: undefined
    });

    // Update state to reflect the host change and clear listing
    this.setState(prevState => ({
      roomMapping: prevState.roomMapping.map(mapping =>
        mapping.roomType === roomTypeId
          ? {
              ...mapping,
              airbnbId: value,
              airbnbListingId: null
            }
          : mapping
      )
    }));
  };

  renderRoomMapping = () => {
    const { roomMapping, errors, availableUnits } = this.state;
    const { property, form } = this.props;

    if (!property || !property.roomTypes || !form) return null;

    const airbnbUserOptions = this.generateAirbnbUserOptions();

    return (
      <>
        {property.roomTypes.map(roomType => {
          const mapping = roomMapping.find(m => m.roomType === roomType._id);
          const isSynced = !!mapping;

          // Filter units for this room type
          const roomTypeUnits = availableUnits.filter(unit => unit.roomType && unit.roomType._id === roomType._id);

          // Generate unit options for this room type
          const unitOptions = roomTypeUnits.map(unit => ({
            value: unit._id,
            displayValue: unit.name,
            key: unit._id
          }));

          // Get Airbnb listing options based on selected host
          const airbnbListingOptions = mapping ? this.generateAirbnbListingOptions(mapping.airbnbId) : [];

          return (
            <Row key={roomType._id} className="ota-row" style={{ marginBottom: '20px' }}>
              <Card
                className="ota-card"
                title={
                  <Row style={{ marginBottom: 10 }}>
                    <span style={{ marginRight: 10 }}>{roomType.name}</span>
                    <Switch checked={isSynced} onChange={this.handleOnToggleRoomSync(roomType._id)} />{' '}
                    {isSynced ? <Tag color="green">Synced</Tag> : <Tag color="red">Not Synced</Tag>}
                  </Row>
                }
              >
                {isSynced && (
                  <>
                    <FormSelection
                      form={form}
                      formLabel={intl.get('hostConnect.integration.headerLabels.masterUnit').d('Master Unit')}
                      name={`masterUnit_${roomType._id}`}
                      requiredErrorMessage={intl.get('hostConnect.integration.placeholder.selectMasterUnitMsg').d('Please select a master unit')}
                      placeholder={intl.get('hostConnect.integration.placeholder.selectMasterUnit').d('Select Master Unit')}
                      selections={unitOptions}
                      onChange={value => this.handleMasterUnitChange(roomType._id, value)}
                      value={mapping && mapping.masterUnitId}
                      error={errors[`masterUnit_${roomType._id}`]}
                      className={styles.formSelectionNoMargin}
                    />

                    <FormSelection
                      form={form}
                      formLabel={intl.get('hostConnect.integration.headerLabels.selectAirbnbId').d('Select Airbnb Host')}
                      name={`airbnbId_${roomType._id}`}
                      requiredErrorMessage={intl.get('hostConnect.integration.placeholder.selectAirbnbIdMsg').d('This is required')}
                      placeholder={intl.get('hostConnect.integration.placeholder.selectAirbnbId').d('Airbnb Host ID')}
                      selections={airbnbUserOptions}
                      onChange={this.handleAirbnbHostChange(roomType._id)}
                      value={mapping && mapping.airbnbId}
                      error={errors[`airbnbId_${roomType._id}`]}
                      className={styles.formSelectionNoMargin}
                    />

                    <FormSelection
                      form={form}
                      formLabel={intl.get('hostConnect.integration.headerLabels.selectListingAirbnb').d('Select Airbnb Listing')}
                      name={`airbnbListingId_${roomType._id}`}
                      requiredErrorMessage={intl.get('hostConnect.integration.placeholder.selectAirbnbIdMsg').d('This is required')}
                      placeholder={intl.get('hostConnect.integration.placeholder.selectListingAirbnb').d('Select Available Units')}
                      selections={airbnbListingOptions}
                      onChange={value => this.handleListingChange(roomType._id, value)}
                      value={mapping && mapping.airbnbListingId}
                      disabled={!mapping.airbnbId}
                      error={errors[`airbnbListingId_${roomType._id}`]}
                      className={styles.formSelectionNoMargin}
                    />

                    {mapping && mapping.connectionExists && mapping.hasConflict && (
                      <Alert
                        style={{ marginTop: 10 }}
                        message={intl
                          .get('integration.warning.existingConnection')
                          .d('Warning: This Airbnb listing is already connected to a different room type.')}
                        description={intl
                          .get('integration.warning.existingConnectionDesc')
                          .d('Creating this connection may affect the existing room type integration.')}
                        type="warning"
                        showIcon
                      />
                    )}
                    {mapping && mapping.connectionExists && mapping.isEdit && (
                      <Alert
                        style={{ marginTop: 10 }}
                        message={intl.get('integration.info.editingConnection').d('You are editing an existing room type connection.')}
                        type="info"
                        showIcon
                      />
                    )}

                    <Row style={{ marginTop: '20px' }}>
                      <OTARateInput rate={mapping.rate || {}} updateRates={this.updateRate(roomType._id)} currency={this.state.currency} />
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                      <Button onClick={this.handleAdvanceOptionsClick(roomType._id)} disabled={!mapping.masterUnitId || !mapping.airbnbListingId}>
                        {intl.get('hostConnect.integration.button.advanceOptions').d('Advanced Options')}
                      </Button>
                    </Row>

                    {mapping.isAdvanceOptionModalVisible && (
                      <AirbnbAdvanceOptionModal
                        isVisible={mapping.isAdvanceOptionModalVisible}
                        onClose={this.handleAdvanceOptionsClose(roomType._id)}
                        onConfirm={this.handleAdvanceOptionsSubmit(roomType._id)}
                        defaultValues={mapping.advanceOptions}
                        rateModifierTypeConstants={this.state.rateModifierTypeConstants}
                        rateModifierSignConstants={this.state.rateModifierSignConstants}
                        roomType={roomType}
                        rate={mapping.rate || {}}
                        currency={this.state.currency}
                        airbnbListingId={mapping.airbnbListingId}
                        unitId={mapping.masterUnitId}
                        isFullSync={false}
                      />
                    )}
                  </>
                )}
              </Card>
            </Row>
          );
        })}
      </>
    );
  };

  render() {
    const { existingUnitSyncs } = this.props;
    const { isLoading, isSaving, errors } = this.state;

    // If there are existing unit syncs, show warning and disable form
    if (existingUnitSyncs && existingUnitSyncs.length > 0) {
      return (
        <Card>
          <Alert
            message={intl.get('integration.warning.existingUnitSync').d('Cannot create room type connections')}
            description={
              <div>
                {intl.get('integration.warning.unitSyncExists').d('This property already has unit-level Airbnb integrations:')}
                <ul>
                  {existingUnitSyncs.map(sync => (
                    <li key={sync.unitId}>{sync.unitName}</li>
                  ))}
                </ul>
                {intl
                  .get('integration.warning.removeUnitSync')
                  .d('Please remove the unit-level integrations first before creating room type connections.')}
              </div>
            }
            type="error"
            showIcon
          />
        </Card>
      );
    }

    return (
      <Row className="scroll-bar-style">
        <Card>
          <Form>
            {!isLoading ? (
              <>
                {this.renderRoomMapping()}
                {errors.roomMapping && <Alert style={{ marginBottom: 10 }} message={errors.roomMapping} type="error" showIcon />}
                <Row className="ota-row" style={{ marginTop: 20 }}>
                  <Button type="primary" onClick={this.onConfirm} disabled={isSaving}>
                    {intl.get('integration.button.confirm').d('Confirm')}
                  </Button>
                </Row>
              </>
            ) : (
              <Skeleton active={true} />
            )}
          </Form>
        </Card>
      </Row>
    );
  }
}

AirbnbHotelForm.propTypes = {
  property: PropTypes.object.isRequired,
  onAfterSave: PropTypes.func,
  hotelIntegrations: PropTypes.array,
  selectedIntegrationSourceCode: PropTypes.string,
  form: PropTypes.object,
  listingIntegration: PropTypes.object,
  history: PropTypes.object,
  checkIsAdminReadOnly: PropTypes.func,
  propertyHostId: PropTypes.string,
  existingUnitSyncs: PropTypes.array
};

export default Form.create()(AirbnbHotelForm);
