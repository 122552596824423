import React, { Component } from 'react';
import { Form, Button, Card, Skeleton, Alert, Row, Col, Icon, Modal, Tooltip, notification } from 'antd';
import PropTypes from 'prop-types';

import FormSelection from 'components/FormSelection/FormSelection';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

import { getConstants } from 'utils/apis/constants';
import {
  getAirbnbs,
  getAirbnbListings,
  pushNewAirbnb,
  linkExistingAirbnb,
  updateAirbnb,
  checkContentReadinessAirbnb,
  getRoomTypeIntegrations
} from 'utils/apis/integration';
import { generatePercentageFromDisplay, guard } from 'utils/general';
import { getWebRateByRoomType } from 'utils/apis/rate';

import OTARateInput from '../../../OTARateInput/OTARateInput';
import AirbnbAdvanceOptionModal from './components/AirbnbAdvanceOptionModal';
import intl from 'react-intl-universal';

import '../../../../../OTAIntegration.css';

const STANDARD_RATE_CODE = 'STD';

const MissingFieldsAlert = ({ missingFields, unitId }) => (
  <Row type="flex">
    <Col span={24}>
      <Alert
        message={intl.get('hostConnect.integration.message.insufficientData').d('Insufficient Listing Data')}
        description={
          <div>
            <div style={{ marginBottom: '10px' }}>
              {intl
                .get('hostConnect.integration.message.insufficientDesc')
                .d("The following detail required by Airbnb is missing in Host Platform's listing:")}
            </div>
            {missingFields.map((missingField, i) => {
              return (
                <div key={missingField}>{i + 1 + ') ' + intl.get(`hostConnect.integration.contentReadiness.${missingField}`).d(missingField)}</div>
              );
            })}
            <Tooltip
              overlayClassName="medium-font"
              placement="right"
              title={
                <div>
                  <p>
                    {intl
                      .get('hostConnect.integration.message.insufficientTooltip5')
                      .d('You can still sync rates & availability to an existing listing.')}
                  </p>
                  <p>
                    {intl
                      .get('hostConnect.integration.message.insufficientTooltip')
                      .d('If you want to push this unit as a new listing, or fully synchronize content, rates and avaialbility, please')}
                    <a rel="noopener noreferrer" target="_blank" href={`/listing/${unitId}`}>
                      {' '}
                      {intl.get('hostConnect.integration.message.insufficientTooltip2').d('update the unit ')}
                    </a>
                    {intl.get('hostConnect.integration.message.insufficientTooltip3').d('to complete the requirement')}
                  </p>
                </div>
              }
            >
              <div style={{ marginTop: '10px' }}>
                <Icon type="question-circle" theme="filled" />
                {intl.get('hostConnect.integration.message.insufficientTooltip4').d('What should I do?')}
              </div>
            </Tooltip>
          </div>
        }
        type="warning"
      />
    </Col>
  </Row>
);

const ConfirmationModal = ({ isVisible, onConfirm, onClose, isLoading, text }) => {
  return (
    <Modal
      title={intl.get('hostConnect.integration.headerLabels.syncIntegration').d('Sync Integration')}
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onClose}
      okText={intl.get('hostConnect.integration.headerLabels.accept').d('Accept')}
      cancelText={intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      <p>{text}</p>
    </Modal>
  );
};

class AirbnbForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missingFields: [],
      airbnbUsers: [],
      airbnbListings: [],
      syncCategory: {},
      unitAirbnbErrors: [],
      advanceOptions: {},
      rate: {},
      isRoomTypeIntegrated: false,

      isLoading: true,
      isModalVisible: false,
      isSaving: false,
      isAdvanceOptionModalVisible: false,
      rateModifierTypeConstants: [],
      rateModifierSignConstants: [],
      currency: 'MYR'
    };
  }

  componentDidMount = () => {
    this.loadRateObject();

    Promise.all([this.loadConstants(), this.loadAirbnbData(), this.loadAirbnbMissingFields(), this.checkRoomTypeIntegration()]).then(() => {
      this.setState({
        isLoading: false
      });
    });

    const { listingIntegration } = this.props;
    if (listingIntegration && listingIntegration.currency) {
      this.setState({ currency: listingIntegration.currency });
    }
  };

  checkRoomTypeIntegration = async () => {
    const { unit, propertyId } = this.props;
    if (!unit || !unit.roomType || !unit.roomType._id) return;

    try {
      const response = await getRoomTypeIntegrations(propertyId);

      if (response && response.data) {
        const hasIntegration = response.data.roomTypes.some(roomType => roomType.roomType._id === unit.roomType._id);
        this.setState({ isRoomTypeIntegrated: hasIntegration });
      }
    } catch (error) {
      console.error('Failed to check room type integration:', error);
    }
  };

  loadConstants = async () => {
    const syncCategory = await getConstants('airbnbSyncCategory').then(res => res.data);
    const rateModifierTypeConstants = await getConstants('rateModifierType').then(res => Object.values(res.data));
    const rateModifierSignConstants = await getConstants('rateModifierSign').then(res => Object.values(res.data));

    this.setState({
      syncCategory,
      rateModifierTypeConstants,
      rateModifierSignConstants
    });
  };

  loadAirbnbData = () => {
    const { propertyHostId } = this.props;

    return Promise.all([
      getAirbnbListings({ hosts: propertyHostId }).then(airbnbListings => {
        this.setState({
          airbnbListings: airbnbListings.data
        });
      }),
      getAirbnbs({ host: propertyHostId }).then(airbnbUsers => {
        this.setState({
          airbnbUsers: airbnbUsers.data
        });
      })
    ]);
  };

  loadAirbnbMissingFields = () => {
    const { unit } = this.props;
    checkContentReadinessAirbnb(unit._id).then(resStatus => {
      if (resStatus && resStatus.status === 200 && resStatus.data) {
        console.log(resStatus.data.missingFields);
        if (resStatus.data.missingFields && resStatus.data.missingFields.length > 0) {
          this.setState({
            unitAirbnbErrors: resStatus.data.missingFields
          });
        }
      }
    });
  };

  loadRateObject = () => {
    const { listingIntegration } = this.props;

    if (listingIntegration && listingIntegration.rate) {
      const { rate } = listingIntegration;
      rate.isDerived = !!rate.isDerived;
      this.setState({ rate });
    } else {
      this.getWebRate();
    }
  };

  getWebRate = async () => {
    const {
      unit: { roomType }
    } = this.props;
    const webRates = await getWebRateByRoomType(roomType._id);
    const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
    if (stdRate) {
      const { weekday, weekend, isDerived } = stdRate;
      this.updateRate({ weekday, weekend, isDerived });
    }
  };

  updateRate = newRates => {
    const { rate } = this.state;
    const updatedRate = { ...rate, ...newRates };
    this.setState({ rate: updatedRate });
  };

  generateAirbnbUserOptions = () => {
    const { airbnbUsers } = this.state;
    return airbnbUsers.map(airbnbUser => ({
      value: airbnbUser._id,
      displayValue: `${airbnbUser.userId} | ${airbnbUser.nickname || intl.get('hostConnect.integration.message.nickname').d('Nickname Not Set')}`,
      key: airbnbUser._id
    }));
  };

  generateAirbnbListingOptions = (airbnbId, airbnbListingId) => {
    const { listingIntegration, form } = this.props;
    const { airbnbListings } = this.state;

    const airbnbListingFiltered = airbnbListings.filter(airbnbListing => {
      return airbnbListing.airbnb === airbnbId && (!airbnbListing.unit || airbnbListing.unit === listingIntegration._id);
    });

    if (airbnbListingId && !airbnbListingFiltered.find(airbnbListing => airbnbListing._id === airbnbListingId)) {
      form.resetFields(['airbnbListingId']);
    }

    return airbnbListingFiltered.map(airbnbListing => ({
      value: airbnbListing._id,
      displayValue: `${airbnbListing.listingId} | ${(airbnbListing.data && airbnbListing.data.listing && airbnbListing.data.listing.name) ||
        intl.get('hostConnect.integration.message.noListing').d('No Listing Name')}`,
      key: airbnbListing._id
    }));
  };

  handleOnSave = () => {
    const {
      onAfterSave,
      onClose,
      unit,
      form: { validateFields }
    } = this.props;
    const { advanceOptions, rate } = this.state;

    validateFields({ force: true }, async (err, values) => {
      if (!err) {
        this.setState({
          isSaving: true
        });

        try {
          await this.sendUpdateAirbnbToBackend({ unitId: unit._id, ...values, rate, payload: advanceOptions });
          this.setState({
            isSaving: false,
            isModalVisible: false
          });
          onAfterSave();
          onClose();
        } catch (e) {
          this.setState({
            isSaving: false
          });
          notification.error({
            message: (
              <div>
                <div>{intl.get('hostConnect.integration.message.somethingWrong').d('Something went wrong with message:')} </div>
                <div>{e.message}</div>
              </div>
            )
          });
        }
      }
    });
  };

  handleOnAdvanceOptionsClick = isVisible => e => {
    e.preventDefault();

    this.setState({
      isAdvanceOptionModalVisible: isVisible
    });
  };

  handleOnAdvanceOptionsSave = payload => {
    const processedRate = this.massageProcessedRate(payload.rateDistributionPayload);

    this.setState({
      isAdvanceOptionModalVisible: false,
      advanceOptions: payload,
      rate: processedRate
    });
  };

  sendUpdateAirbnbToBackend = ({ unitId, syncCategory, airbnbId, rate, airbnbListingId, isPushNew, payload }) => {
    if (this.checkIsEdit()) {
      return updateAirbnb(unitId, syncCategory, rate, payload);
    } else if (isPushNew) {
      return pushNewAirbnb(unitId, airbnbId, syncCategory, rate, payload);
    } else {
      return linkExistingAirbnb(unitId, airbnbListingId, syncCategory, rate, payload);
    }
  };

  checkIsEdit = () => {
    const { listingIntegration } = this.props;

    return !!listingIntegration && Object.keys(listingIntegration).length > 0;
  };

  showConfirmationModal = () => {
    const { form } = this.props;

    form.validateFields({ force: true }, async (err, values) => {
      if (!err) {
        this.setState({
          isModalVisible: true
        });
      }
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isModalVisible: false
    });
  };

  generateModalMessage = () => {
    const { form } = this.props;

    const isPushNew = form.getFieldValue('isPushNew');

    if (this.checkIsEdit()) {
      return intl.get('hostConnect.integration.message.saveChanges').d('Save changes to the integration?');
    } else if (isPushNew) {
      return intl.get('hostConnect.integration.message.createIntegration').d('Are you sure you want to create the integration?');
    } else {
      return intl.get('hostConnect.integration.message.merge').d('Are you sure you want to merge this existing listing?');
    }
  };

  getAirbnbListingIdValue = () => {
    const { form, listingIntegration } = this.props;

    const formAirbnbListingId = form.getFieldValue('airbnbListingId');

    if (formAirbnbListingId) {
      return formAirbnbListingId;
    } else {
      return this.checkIsEdit() && listingIntegration ? listingIntegration.airbnbListingId : undefined;
    }
  };

  getSyncCategoryValue = (isDefault = false) => {
    const { form, listingIntegration } = this.props;
    const { unitAirbnbErrors, syncCategory } = this.state;

    const isFieldsEnough = !unitAirbnbErrors || unitAirbnbErrors.length === 0;

    if (!isDefault && form.getFieldValue('syncCategory')) {
      return form.getFieldValue('syncCategory');
    }

    return isFieldsEnough ? listingIntegration.syncCategory || syncCategory.FULL : syncCategory.PARTIAL;
  };

  massageProcessedRate = rateDistributionPayload => {
    const { rate, rateModifierSignConstants, rateModifierTypeConstants } = this.state;

    let processedRate = {};
    const rateObject = rateDistributionPayload.length > 0 && rateDistributionPayload[0].rates.length > 0 && rateDistributionPayload[0].rates[0];
    if (!!rateObject) {
      processedRate = {
        weekday: rateObject.rate.weekdayRate,
        weekend: rateObject.rate.weekendRate,
        isDerived: rateObject.rate.isDerived
      };
      const isDerived = rateObject.rate.isDerived;
      if (!!isDerived) {
        const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
        const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
        let amount = rateObject.rate.modifierAmount;

        amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

        processedRate.calculation = {
          amount,
          type: rateObject.rate.modifierType,
          isPositive
        };
      }
    } else {
      processedRate = rate;
    }

    return processedRate;
  };

  handleListingChange = async value => {
    const { airbnbListings } = this.state;

    const selectedListing = airbnbListings.find(airbnbListing => airbnbListing._id === value);
    if (guard(() => selectedListing.data.pricing_setting.listing_currency, false)) {
      this.setState({ currency: selectedListing.data.pricing_setting.listing_currency });
    }
  };

  render() {
    const { unit, onClose, form, listingIntegration, history, checkIsAdminReadOnly, propertyHostId } = this.props;
    const {
      isLoading,
      isModalVisible,
      isAdvanceOptionModalVisible,
      isSaving,
      syncCategory,
      unitAirbnbErrors,
      advanceOptions,
      rate,
      rateModifierTypeConstants,
      rateModifierSignConstants,
      currency,
      isRoomTypeIntegrated
    } = this.state;

    if (isRoomTypeIntegrated) {
      return (
        <Card>
          <Alert
            message={intl.get('hostConnect.integration.message.roomTypeIntegrated').d('Room Type Already Integrated')}
            description={
              <div>
                {intl
                  .get('hostConnect.integration.message.roomTypeIntegratedDesc')
                  .d(
                    'This room type is already integrated with Airbnb. You cannot create a unit-level integration when a room type integration exists.'
                  )}
              </div>
            }
            type="error"
            showIcon
          />
        </Card>
      );
    }

    const syncMethodSelections = [
      { value: true, label: intl.get('hostConnect.integration.headerLabels.pushListing').d('Push New Listing') },
      { value: false, label: intl.get('hostConnect.integration.headerLabels.merge').d('Merge Existing Listing') }
    ];

    const { airbnbId, airbnbListingId } = form.getFieldsValue();
    const airbnbUserOptions = this.generateAirbnbUserOptions();
    const airbnbListingOptions = this.generateAirbnbListingOptions(
      airbnbId || listingIntegration.airbnbId,
      airbnbListingId || listingIntegration.airbnbListingId
    );
    const syncCategorySelections = [
      {
        key: syncCategory.FULL,
        value: syncCategory.FULL,
        displayValue: intl.get('hostConnect.integration.headerLabels.fullSync').d('Full Synchronization')
      },
      {
        key: syncCategory.PARTIAL,
        value: syncCategory.PARTIAL,
        displayValue: intl.get('hostConnect.integration.headerLabels.ratesAvailability').d('Rates and Availability')
      }
    ];
    const isFieldsEnough = !unitAirbnbErrors || unitAirbnbErrors.length === 0;
    const isPushNew = form.getFieldValue('isPushNew') !== undefined ? form.getFieldValue('isPushNew') : isFieldsEnough;

    return (
      <Row className="scroll-bar-style">
        <Card>
          <ConfirmationModal
            isVisible={isModalVisible}
            onConfirm={this.handleOnSave}
            onClose={this.hideConfirmationModal}
            isLoading={isSaving}
            text={this.generateModalMessage()}
          />
          {isAdvanceOptionModalVisible && (
            <AirbnbAdvanceOptionModal
              isVisible={isAdvanceOptionModalVisible}
              isFullSync={this.getSyncCategoryValue() === syncCategory.FULL}
              onConfirm={this.handleOnAdvanceOptionsSave}
              onClose={this.handleOnAdvanceOptionsClick(false)}
              unitId={unit._id}
              airbnbListingId={this.getAirbnbListingIdValue()}
              defaultValues={advanceOptions}
              rateModifierTypeConstants={rateModifierTypeConstants}
              rateModifierSignConstants={rateModifierSignConstants}
              rate={rate}
              roomType={unit.roomType}
              history={history}
              currency={currency}
            />
          )}
          <Form>
            {!isLoading ? (
              <>
                {!isFieldsEnough && <MissingFieldsAlert missingFields={unitAirbnbErrors} unitId={unit._id} />}
                <FormRadioButton
                  form={form}
                  formLabel={intl.get('hostConnect.integration.headerLabels.pushMerge').d('Push New | Merge Existing')}
                  name="isPushNew"
                  buttonStyle="solid"
                  defaultValue={isFieldsEnough}
                  shouldDisable={this.checkIsEdit() || !isFieldsEnough}
                  selections={syncMethodSelections}
                />
                <FormSelection
                  form={form}
                  formLabel={intl.get('hostConnect.integration.headerLabels.selectAirbnbId').d('Select Airbnb ID')}
                  name="airbnbId"
                  requiredErrorMessage={intl.get('hostConnect.integration.placeholder.selectAirbnbIdMsg').d('This is required')}
                  defaultValue={listingIntegration.airbnbId}
                  shouldDisable={this.checkIsEdit()}
                  placeholder={intl.get('hostConnect.integration.placeholder.selectAirbnbId').d('Airbnb User ID')}
                  selections={airbnbUserOptions}
                />
                {(!isPushNew || this.checkIsEdit()) && (
                  <FormSelection
                    form={form}
                    formLabel={intl.get('hostConnect.integration.headerLabels.selectListingAirbnb').d('Select Airbnb Listing')}
                    name="airbnbListingId"
                    requiredErrorMessage={intl.get('hostConnect.integration.placeholder.selectAirbnbIdMsg').d('This is required')}
                    defaultValue={this.checkIsEdit() && listingIntegration ? listingIntegration.airbnbListingId : undefined}
                    shouldDisable={this.checkIsEdit()}
                    placeholder={intl.get('hostConnect.integration.placeholder.selectListingAirbnb').d('Select Available Units')}
                    selections={airbnbListingOptions}
                    onChange={this.handleListingChange}
                  />
                )}
                <FormRadioButton
                  form={form}
                  formLabel={intl.get('hostConnect.integration.headerLabels.syncType').d('Synchronization Type')}
                  name="syncCategory"
                  buttonStyle="solid"
                  defaultValue={this.getSyncCategoryValue(true)}
                  shouldDisable={!isFieldsEnough}
                  selections={syncCategorySelections}
                />
                <Row>
                  <Col span={24}>
                    <OTARateInput rate={rate} updateRates={this.updateRate} currency={currency} />
                  </Col>
                  <Col span={24}>
                    <br />
                    <a href=" " onClick={this.handleOnAdvanceOptionsClick(true)} style={{ marginBottom: 30 }}>
                      {intl.get('hostConnect.integration.headerLabels.advanced').d('Advanced Options')}
                    </a>
                  </Col>
                </Row>
                <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
                  <Col>
                    <Button type="secondary" onClick={onClose}>
                      {intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      disabled={isLoading || (listingIntegration && listingIntegration.status === 'pushing') || checkIsAdminReadOnly()}
                      onClick={this.showConfirmationModal}
                    >
                      {intl.get('hostConnect.integration.headerLabels.save').d('Save')}
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <Skeleton active={true} />
            )}
          </Form>
        </Card>
      </Row>
    );
  }
}

AirbnbForm.propTypes = {
  listingIntegration: PropTypes.object,
  unit: PropTypes.object.isRequired,
  onAfterSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired
};

AirbnbForm.defaultProps = {
  listingIntegration: {},
  onAfterSave: () => {}
};

export default Form.create()(AirbnbForm);
