import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Tabs, Table, Card, Button, Tooltip, Icon, Input, Avatar } from 'antd';
import moment from 'moment';

import { buildIOTAFormUri } from 'utils/routes';
import { getIntegrationTypesConstant } from 'utils/general';
import { getIntegratedListings, getIntegratedHotels } from 'utils/apis/integration';
import { guard } from 'utils/general';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import agodaHomesLogo from 'images/agodahomes-logo-icon.png';
import bookingcomLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';

import OTAStatusIcon from './components/OTAStatusIcon';
import CSVDownload from 'components/CSVDownload/CSVDownload';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;
const { LISTING_TYPE, HOTEL_TYPE } = getIntegrationTypesConstant();

class OTAListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingOTA: [],
      hotelOTA: [],
      isListingLoading: true,
      isHotelLoading: true,
      type: LISTING_TYPE.code,
      csvData: [],
      csvName: ''
    };

    this.tabChange = this.tabChange.bind(this);
  }

  tabChange(e) {
    if (e === 'listing') {
      this.setState({
        type: 'listing'
      });
    } else {
      this.setState({ type: 'hotel' });
    }
  }

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  componentWillMount() {
    getIntegratedListings()
      .then(listingRes => {
        let listingOTA = [];
        if (listingRes && listingRes.status === 200) {
          listingOTA = listingRes.data;
        }
        getIntegratedHotels()
          .then(hotelRes => {
            let hotelOTA = [];
            if (hotelRes && hotelRes.status === 200) {
              hotelOTA = hotelRes.data;
            }
            this.setState({
              listingOTA,
              hotelOTA,
              isListingLoading: false,
              isHotelLoading: false
            });
          })
          .catch(ex => {
            console.log(ex, 'Error occurred when retrieving integrated hotels.');
          });
      })
      .catch(ex => {
        console.log(ex, 'Error occurred when retrieving integrated listings.');
      });
  }

  handleOnClickCSVDownload = async (data, name) => {
    // const setName = setCSVName => {
    const currentTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = `${name}_Integration.${currentTime}.csv`;
    // };
    const constructCSVData = (data, name) => {
      if (name === 'Listing') {
        return data.map(d => {
          return {
            'Unit Name': guard(() => d.unit.name, '-'),
            'Property Name': guard(() => d.property.name, '-'),
            Host: guard(() => d.host.name, '-'),
            'Airbnb Property Name': guard(() => d.ota.airbnb.listingName, '-'),
            'Airbnb Sync Status': guard(() => d.ota.airbnb.status, '-'),
            'AgodaHomes Property Name': guard(() => d.ota.agodahomes.listingName, '-'),
            'AgodaHomes Sync Status': guard(() => d.ota.agodahomes.status, '-'),
            Currency: guard(() => {
              if (guard(() => d.ota.airbnb.currency, false)) {
                return d.ota.airbnb.currency;
              } else if (d.ota.agodahomes.currency) {
                return d.ota.agodahomes.currency;
              }
            }, '-')
          };
        });
      } else if (name === 'Hotel') {
        return data.map(d => {
          return {
            'Property Name': guard(() => d.property.name, '-'),
            Host: guard(() => d.property.host.name, '-'),
            'BDC ID': guard(() => d.ota.bookingcom.foreignPropertyId, '-'),
            'BDC Property Name': guard(() => d.ota.bookingcom.foreignPropertyName, '-'),
            'BDC Sync Status': guard(() => d.ota.bookingcom.status, '-'),
            'BDC Currency': guard(() => d.ota.bookingcom.currency, '-'),
            // 'BDC number of Rooms': guard(() => d.ota.bookingcom.roomTypes.length, '-'),
            'ctrip ID': guard(() => (d.ota.ctrip.foreignPropertyId ? d.ota.ctrip.foreignPropertyId : d.ota.ctrip.code), '-'),
            'ctrip Sync Status': guard(() => d.ota.ctrip.status + ', ' + d.ota.ctrip.statusMsg, '-'),
            'ctrip Currency': guard(() => d.ota.ctrip.currency, '-'),
            // 'ctrip number of Rooms': guard(() => d.ota.ctrip.roomTypes.length, '-'),
            'Expedia ID': guard(() => d.ota.expedia.foreignPropertyId, '-'),
            'Expedia Property Name': guard(() => d.ota.expedia.foreignPropertyName, '-'),
            'Expedia Sync Status': guard(() => d.ota.expedia.status, '-'),
            'Expedia Currency': guard(() => d.ota.expedia.currency, '-'),
            // 'Expedia number of Rooms': guard(() => d.ota.expedia.roomTypes.length, '-'),
            'Agoda ID': guard(() => d.ota.agoda.foreignPropertyId, '-'),
            'Agoda Property Name': guard(() => d.ota.agoda.foreignPropertyName, '-'),
            'Agoda Sync Status': guard(() => d.ota.agoda.status, '-'),
            'Agoda Currency': guard(() => d.ota.agoda.currency, '-'),
            // 'Agoda number of Rooms': guard(() => d.ota.agoda.roomTypes.length, '-'),
            'ctripCM ID': guard(() => d.ota.ctripCM.foreignPropertyId, '-'),
            'ctripCM Property Name': guard(() => d.ota.ctripCM.foreignPropertyName, '-'),
            'ctripCM Sync Status': guard(() => d.ota.ctripCM.status, '-'),
            'ctripCM Currency': guard(() => d.ota.ctripCM.currency, '-'),
            // 'ctripCM number of Rooms': guard(() => d.ota.ctripCM.roomTypes.length, '-'),
            'traveloka ID': guard(() => d.ota.traveloka.foreignPropertyId, '-'),
            'traveloka Property Name': guard(() => d.ota.traveloka.foreignPropertyName, '-'),
            'traveloka Sync Status': guard(() => d.ota.traveloka.status, '-'),
            'traveloka Currency': guard(() => d.ota.traveloka.currency, '-'),
            // 'traveloka number of Rooms': guard(() => d.ota.traveloka.roomTypes.length, '-'),
            'tiketcom ID': guard(() => d.ota.tiketcom.foreignPropertyId, '-'),
            'tiketcom Property Name': guard(() => d.ota.tiketcom.foreignPropertyName, '-'),
            'ticketcom Sync Status': guard(() => d.ota.tiketcom.status, '-'),
            'ticketcom Currency': guard(() => d.ota.tiketcom.currency, '-'),
            // 'ticketcom number of Rooms': guard(() => d.ota.tiketcom.roomTypes.length, '-'),
            'Airbnb Room Type Name': guard(() => d.ota.airbnb.roomTypeName, '-'),
            'Airbnb Listing ID': guard(() => d.ota.airbnb.airbnbListingId, '-'),
            'Airbnb Sync Status': guard(() => d.ota.airbnb.status, '-'),
            'Airbnb Currency': guard(() => d.ota.airbnb.currency, '-')
          };
        });
      }
    };

    this.setState({
      csvName: csvName,
      csvData: constructCSVData(data, name)
    });
  };

  render() {
    let { listingOTA, hotelOTA, isListingLoading, isHotelLoading, csvData, csvName } = this.state;

    const listingColumns = [
      {
        title: intl.get('hostConnect.integration.tableColumns.unit').d('Unit'),
        dataIndex: 'unit',
        key: 'unit',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.unit.props.children.localeCompare(b.unit.props.children),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.unit').d('Search Unit Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUnitListingIntegration"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>{' '}
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) =>
          record.unit.props.children
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      },
      {
        title: intl.get('hostConnect.integration.tableColumns.property').d('Property'),
        dataIndex: 'property',
        key: 'property',
        sorter: (a, b) => a.property.localeCompare(b.property),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.property').d('Search Property Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyListingIntegration"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>{' '}
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          return record.property.toLowerCase().includes(value.toLowerCase());
        }
      },
      {
        title: (
          <Tooltip title="Airbnb">
            <Avatar size="small" src={airbnbLogo} />
          </Tooltip>
        ),
        dataIndex: 'airbnb',
        key: 'airbnb',
        width: '100px',
        sorter: (a, b) => a.airbnb.props.type.localeCompare(b.airbnb.props.type)
      },
      {
        title: (
          <Tooltip title="Agoda Homes">
            <Avatar size="small" src={agodaHomesLogo} />
          </Tooltip>
        ),
        dataIndex: 'agodahomes',
        key: 'agodahomes',
        width: '100px',
        sorter: (a, b) => a.agodahomes.props.type.localeCompare(b.agodahomes.props.type)
      }
    ];
    let listingData = listingOTA
      .filter(lOTA => lOTA.ota.airbnb || lOTA.ota.agodahomes)
      .map(lOTA => {
        return {
          unit: (
            <Link to={buildIOTAFormUri({ propertyId: lOTA.property._id, unitId: lOTA.unit._id, integrationType: LISTING_TYPE.code })}>
              {lOTA.unit.name}
            </Link>
          ),
          property: lOTA.property.name,
          airbnb: lOTA.ota.airbnb ? lOTA.ota.airbnb.status : null,
          agodahomes: lOTA.ota.agodahomes ? lOTA.ota.agodahomes.status : null
        };
      });
    listingData = listingData.map(listingD => {
      return {
        ...listingD,
        airbnb: listingD.airbnb ? (
          listingD.airbnb === 'approved' ? (
            <OTAStatusIcon type="ok" />
          ) : listingD.airbnb === 'pending' || listingD.airbnb === 'new' ? (
            <OTAStatusIcon type="pending" />
          ) : listingD.airbnb === 'error' || listingD.airbnb === 'rejected' ? (
            <OTAStatusIcon type="error" />
          ) : listingD.airbnb === 'pushing' ? (
            <OTAStatusIcon type="pending" tooltip={intl.get('hostConnect.integration.otaStatus.processing').d('System is proccessing')} />
          ) : (
            <OTAStatusIcon type="unknown" />
          )
        ) : (
          <OTAStatusIcon type="nosync" />
        ),
        // TODO: Change this once agodahomes can detect errors in its status
        agodahomes: listingD.agodahomes ? <OTAStatusIcon type="ok" /> : <OTAStatusIcon type="nosync" />
      };
    });

    const propertyColumn = [
      {
        title: intl.get('hostConnect.integration.tableColumns.property').d('Property'),
        dataIndex: 'property',
        key: 'property',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.property.props.children.localeCompare(b.property.props.children),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.property').d('Search Property Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyHotelIntegration"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>{' '}
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.property && record.property.props.children.toLowerCase().includes(value.toLowerCase())
      },
      // {
      //   title: (
      //     <Tooltip title="Ctrip">
      //       <Avatar size="small" src={ctripLogo} />
      //     </Tooltip>
      //   ),
      //   dataIndex: 'ctrip',
      //   key: 'ctrip',
      //   width: '100px',
      //   sorter: (a, b) => a.ctrip.props.type.localeCompare(b.ctrip.props.type)
      // },
      {
        title: (
          <Tooltip title="Booking.com">
            <Avatar size="small" src={bookingcomLogo} />
          </Tooltip>
        ),
        dataIndex: 'bookingcom',
        key: 'bookingcom',
        width: '100px',
        sorter: (a, b) => a.bookingcom.props.type.localeCompare(b.bookingcom.props.type)
      },
      {
        title: (
          <Tooltip title="Expedia">
            <Avatar size="small" src={expediaLogo} />
          </Tooltip>
        ),
        dataIndex: 'expedia',
        key: 'expedia',
        width: '100px',
        sorter: (a, b) => a.expedia.props.type.localeCompare(b.expedia.props.type)
      },
      {
        title: (
          <Tooltip title="Agoda">
            <Avatar size="small" src={agodaLogo} />
          </Tooltip>
        ),
        dataIndex: 'agoda',
        key: 'agoda',
        width: '100px',
        sorter: (a, b) => a.agoda.props.type.localeCompare(b.agoda.props.type)
      },
      {
        title: (
          <Tooltip title="Ctrip">
            <Avatar size="small" src={ctripLogo} />
          </Tooltip>
        ),
        dataIndex: 'ctripCM',
        key: 'ctripCM',
        width: '100px',
        sorter: (a, b) => a.ctripCM.props.type.localeCompare(b.ctripCM.props.type)
      },
      {
        title: (
          <Tooltip title="Traveloka">
            <Avatar size="small" src={travelokaLogo} />
          </Tooltip>
        ),
        dataIndex: 'traveloka',
        key: 'traveloka',
        width: '100px',
        sorter: (a, b) => a.traveloka.props.type.localeCompare(b.traveloka.props.type)
      },
      {
        title: (
          <Tooltip title="Tiket.com">
            <Avatar size="small" src={tiketcomLogo} />
          </Tooltip>
        ),
        dataIndex: 'tiketcom',
        key: 'tiketcom',
        width: '100px',
        sorter: (a, b) => a.tiketcom.props.type.localeCompare(b.tiketcom.props.type)
      },
      {
        title: (
          <Tooltip title="Airbnb">
            <Avatar size="small" src={airbnbLogo} />
          </Tooltip>
        ),
        dataIndex: 'airbnb',
        key: 'airbnb',
        width: '100px',
        sorter: (a, b) => a.airbnb.props.type.localeCompare(b.airbnb.props.type)
      }
    ];

    let propertyData = hotelOTA.map(hOTA => {
      return {
        property: <Link to={buildIOTAFormUri({ propertyId: hOTA.property._id, integrationType: HOTEL_TYPE.code })}>{hOTA.property.name}</Link>,
        ctrip: hOTA.ota.ctrip ? hOTA.ota.ctrip.status : null,
        bookingcom: hOTA.ota.bookingcom ? hOTA.ota.bookingcom.status : null,
        expedia: hOTA.ota.expedia ? hOTA.ota.expedia.status : null,
        agoda: hOTA.ota.agoda ? hOTA.ota.agoda.status : null,
        ctripCM: hOTA.ota.ctripCM ? hOTA.ota.ctripCM.status : null,
        traveloka: hOTA.ota.traveloka ? hOTA.ota.traveloka.status : null,
        tiketcom: hOTA.ota.tiketcom ? hOTA.ota.tiketcom.status : null,
        airbnb: hOTA.ota.airbnb ? hOTA.ota.airbnb.status : null
      };
    });

    propertyData = propertyData
      .filter(p => p.ctrip || p.bookingcom || p.expedia || p.agoda || p.ctripCM || p.traveloka || p.tiketcom || p.airbnb)
      .map(propertyD => {
        return {
          ...propertyD,
          ctrip: propertyD.ctrip ? (
            propertyD.ctrip === 'Active' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.ctrip === 'Pending' ? (
              <OTAStatusIcon type="pending" />
            ) : propertyD.ctrip === 'Failed' ? (
              <OTAStatusIcon type="error" />
            ) : (
              <OTAStatusIcon type="unknown" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          bookingcom: propertyD.bookingcom ? (
            propertyD.bookingcom === 'synced' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.bookingcom === 'pushing' ? (
              <OTAStatusIcon type="pending" />
            ) : propertyD.bookingcom === 'nobind' ? (
              <OTAStatusIcon type="error" />
            ) : (
              <OTAStatusIcon type="unknown" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          expedia: propertyD.expedia ? (
            propertyD.expedia === 'Synced' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.expedia === 'Pending' ? (
              <OTAStatusIcon type="pending" />
            ) : propertyD.expedia === 'Not Synced' ? (
              <OTAStatusIcon type="error" />
            ) : (
              <OTAStatusIcon type="unknown" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          // TODO: Change this once agoda can detect errors in its status
          agoda: propertyD.agoda ? <OTAStatusIcon type="ok" /> : <OTAStatusIcon type="nosync" />,
          ctripCM: propertyD.ctripCM ? (
            propertyD.ctripCM === 'synced' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.ctripCM === 'nobind' ? (
              <OTAStatusIcon type="nosync" />
            ) : (
              <OTAStatusIcon type="error" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          traveloka: propertyD.traveloka ? (
            propertyD.traveloka === 'synced' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.traveloka === 'nobind' ? (
              <OTAStatusIcon type="nosync" />
            ) : (
              <OTAStatusIcon type="error" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          tiketcom: propertyD.tiketcom ? (
            propertyD.tiketcom === 'synced' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.traveloka === 'nobind' ? (
              <OTAStatusIcon type="nosync" />
            ) : (
              <OTAStatusIcon type="error" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          ),
          airbnb: propertyD.airbnb ? (
            propertyD.airbnb === 'approved' ? (
              <OTAStatusIcon type="ok" />
            ) : propertyD.airbnb === 'pending' || propertyD.airbnb === 'new' ? (
              <OTAStatusIcon type="pending" />
            ) : propertyD.airbnb === 'error' || propertyD.airbnb === 'rejected' ? (
              <OTAStatusIcon type="error" />
            ) : propertyD.airbnb === 'pushing' ? (
              <OTAStatusIcon type="ok" />
            ) : (
              <OTAStatusIcon type="unknown" />
            )
          ) : (
            <OTAStatusIcon type="nosync" />
          )
        };
      });
    return (
      <Card className="list-card">
        <Tabs defaultActiveKey="listing" onChange={this.tabChange}>
          <TabPane tab={intl.get('hostConnect.integration.integrationType.Listing Integration').d('Listing Integration')} key="listing">
            <div className="list-card-control-section">
              <Link to={buildIOTAFormUri()}>
                <Button
                  id="create-button8-listingint"
                  type="primary"
                  style={{ marginTop: 16 }}
                  icon="plus"
                  checkIsAdminReadOnly={this.props.checkIsAdminReadOnly}
                >
                  {intl.get('hostConnect.integration.headerLabels.createIntegrationLabel').d('Create Integration')}
                </Button>
              </Link>
              <CSVDownload
                id={'csv-button5-listing'}
                filename={csvName}
                data={csvData}
                style={{ marginTop: 16 }}
                onClick={() => this.handleOnClickCSVDownload(listingOTA, 'Listing')}
                isLoading={isListingLoading}
                checkIsAdminReadOnly={this.props.checkIsAdminReadOnly}
              />
            </div>
            <Table scroll={{ x: 1000 }} columns={listingColumns} dataSource={listingData} onChange={this.onChange} loading={isListingLoading} />
          </TabPane>
          <TabPane tab={intl.get('hostConnect.integration.integrationType.Hotel Integration').d('Hotel Integration')} key="hotel">
            <div className="list-card-control-section">
              <Link to={buildIOTAFormUri({ integrationType: HOTEL_TYPE.code })}>
                <Button
                  id="create-button9-hotelint"
                  style={{ marginTop: 16 }}
                  type="primary"
                  icon="plus"
                  checkIsAdminReadOnly={this.props.checkIsAdminReadOnly}
                >
                  {intl.get('hostConnect.integration.headerLabels.addIntegration').d('Add Integration')}
                </Button>
              </Link>
              <CSVDownload
                id={'csv-button5-listing'}
                filename={csvName}
                data={csvData}
                style={{ marginTop: 16 }}
                onClick={() => this.handleOnClickCSVDownload(hotelOTA, 'Hotel')}
                isLoading={isHotelLoading}
                checkIsAdminReadOnly={this.props.checkIsAdminReadOnly}
              />
            </div>
            <Table scroll={{ x: 1000 }} columns={propertyColumn} dataSource={propertyData} onChange={this.onChange} loading={isHotelLoading} />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default withRouter(withAppContext(OTAListing));
