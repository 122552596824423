import { httpClient } from '../httpClient';
import { apiErrorHandler } from './helpers';

const TRAVELOKA_ROUTE = 'traveloka';
const TIKETCOM_ROUTE = 'tiketcom';

export const refreshCode = code => {
  return httpClient.post('/airbnb/code/' + code).catch(apiErrorHandler);
};
export const getIntegratedListings = () => {
  return httpClient.get('/integration/getIntegratedListings').catch(apiErrorHandler);
};

export const getIntegratedHotels = () => {
  return httpClient.get('/integration/getIntegratedHotels').catch(apiErrorHandler);
};

export const getIntegratedListingByUnitId = unitId => {
  return httpClient
    .get(`/integration/listings/${unitId}`)
    .then(res => res.data)
    .catch(apiErrorHandler);
};

export const getIntegratedHotelByPropertyId = propertyId => {
  return httpClient
    .get(`/integration/hotels/${propertyId}`)
    .then(res => res.data)
    .catch(apiErrorHandler);
};

export const getAirbnbs = (query = {}) => {
  return httpClient.get('/airbnb', { params: query }).catch(apiErrorHandler);
};

export const getAirbnbAdvanceOptions = (unitId, airbnbListingId) => {
  return httpClient.get(`/airbnb/unit/${unitId}/advancedSettings`, { params: { airbnbListingId } }).catch(apiErrorHandler);
};

export const getAirbnbUsers = (query = {}) => {
  return httpClient.get('/airbnb/users', { params: query }).catch(apiErrorHandler);
};

export const getAirbnbListings = (query, lite = true) => {
  if (lite) {
    query.select = 'airbnb listingId data.listing.name data.pricing_setting.listing_currency unit';
  }
  return httpClient
    .get(`/airbnb/listings`, {
      params: query
    })
    .catch(apiErrorHandler);
};

export const getRoomTypeIntegrations = propertyId => {
  return httpClient.get(`/airbnb/roomTypeIntegrations/${propertyId}`).catch(apiErrorHandler);
};

export const generateExcelDetails = property => {
  let query = '?propertyIds=';
  if (Array.isArray(property)) {
    for (let i = 0; i < property.length; i++) {
      query += property[i].toString();
    }
  } else {
    query += property.toString();
  }
  return httpClient.get('/ctrip/getMinHotelContent' + query).catch(apiErrorHandler);
};

export const getRoomTypeByProperty = propertyId => {
  return httpClient.get('/roomType?property=' + propertyId).catch(apiErrorHandler);
};

export const getCTrip = propertyId => {
  return httpClient.get('/ctrip?property=' + propertyId).catch(apiErrorHandler);
};

export const updateCTrip = ctripObj => {
  return httpClient
    .put('/ctrip/populated/', ctripObj)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const updateCtripSyncStatus = propertyId =>
  httpClient
    .get('/ctrip/updateSyncStatus' + (propertyId ? `?property=${propertyId}` : ``))
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const unsyncCtripHotel = propertyId =>
  httpClient
    .post('/ctrip/' + propertyId + '/unsyncCtripHotel')
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const pushNewAirbnb = (unitId, airbnbId, syncCategory, rate, advancedOptions) => {
  const payloadBody = {
    rate,
    advancedOptions
  };
  return httpClient.post('/airbnb/pushNew', payloadBody, { params: { unitId, airbnbId, syncCategory } }).catch(apiErrorHandler);
};

export const linkExistingAirbnb = (unitId, listingId, syncCategory, rate, advancedOptions) => {
  const payloadBody = {
    rate,
    advancedOptions,
    ...(advancedOptions &&
      advancedOptions.isRoomTypeInventory && {
        roomTypeId: advancedOptions.roomTypeId,
        isRoomTypeInventory: true
      })
  };
  return httpClient.post('/airbnb/linkExisting', payloadBody, { params: { unitId, listingId, syncCategory } }).catch(apiErrorHandler);
};

export const updateAirbnb = (unitId, syncCategory, rate, advancedOptions) => {
  const payloadBody = {
    rate,
    advancedOptions
  };
  return httpClient.post('/airbnb/update', payloadBody, { params: { unitId, syncCategory } }).catch(apiErrorHandler);
};

// Used for updating individual opportunities separately.
// export const updateAirbnbOpportunities = (listingId, payloadBody) => {
//   return httpClient.put('/airbnb/updateOpportunities/' + listingId, payloadBody).catch(apiErrorHandler);
// };

export const updateAirbnbReview = (reviewId, payloadBody) => {
  return httpClient.put('/airbnb/updateReview/' + reviewId, payloadBody).catch(apiErrorHandler);
};

export const unsyncContentAirbnb = listingId => {
  return httpClient.post('/airbnb/unsyncContent?listingId=' + listingId).catch(apiErrorHandler);
};

export const checkContentReadinessAirbnb = unitId => {
  return httpClient.post('/airbnb/checkContentReadiness?unitId=' + unitId).catch(apiErrorHandler);
};

export const getAgoda = agodaId => {
  let uri = '/agoda';
  if (agodaId) {
    uri = '/agoda/' + agodaId;
  }
  return httpClient.get(uri).catch(apiErrorHandler);
};
export const syncAgoda = (agodaId, propertyId, unitId, roomMapping) => {
  if (!propertyId && !unitId) {
    throw new Error('Either property Id or Unit Id needs to be passed in');
  }
  // unit id < propertyId
  let uri = '/agoda/sync-agoda?agodaId=' + agodaId + '&propertyId=' + propertyId;
  if (unitId) {
    uri = '/agoda/sync-agoda?agodaId=' + agodaId + '&unitId=' + unitId;
  }

  return httpClient.post(uri, roomMapping).catch(apiErrorHandler);
};

export const getAgodas = (hostId, type = '') => {
  const uri = '/agoda';
  const params = {};
  if (type === 'homes') {
    params.isHomes = true;
  } else if (type === 'hotels') {
    params.isHomes = false;
  }
  if (hostId) {
    params.host = hostId;
  }
  return httpClient.get(uri, { params }).catch(apiErrorHandler);
};

export const getAgodaProduct = agodaPropertyId => {
  return httpClient.post(`/agoda/get-product?propertyId=${agodaPropertyId}`).catch(apiErrorHandler);
};

export const updateAgodaHost = (agodaId, hostId, isHomes) => {
  return httpClient
    .put(`/agoda/update-host/${agodaId}`, {
      host: hostId,
      isHomes
    })
    .catch(apiErrorHandler);
};

export const unsyncAgoda = propertyId => {
  return httpClient.post('/agoda/unsync-agoda?propertyId=' + propertyId).catch(apiErrorHandler);
};

export const unsyncAgodaHomes = agodaId => {
  return httpClient.post('/agoda/unsync-agoda?agodaId=' + agodaId).catch(apiErrorHandler);
};

export const updateAirbnbUser = (airbnbId, nickname, host) => {
  let uri = '/airbnb/' + airbnbId;
  return httpClient
    .put(uri, {
      nickname,
      host
    })
    .catch(apiErrorHandler);
};

// export const getAirbnbNotifications = airbnbId => {
//   return httpClient.get('/airbnb/' + airbnbId + '/notifications').catch(apiErrorHandler);
// };

export const getAllAirbnbNotification = airbnbUsers => {
  return httpClient.post(`airbnb/all/notifications`, { airbnbUsers }).catch(apiErrorHandler);
};

export const getAirbnbReviews = confirmationCode => {
  return httpClient.get('/airbnb/' + confirmationCode + '/reviews').catch(apiErrorHandler);
};

// Expedia
export const getExpediaProperties = () => {
  return httpClient.get('/expedia/products').catch(apiErrorHandler);
};

export const getExpediaRoomTypesByPropertyId = async propertyId => {
  return httpClient.get(`/expedia/products/${propertyId}/roomTypes`).catch(apiErrorHandler);
};

export const getExpediaRatePlansByRoomTypeId = async (propertyId, roomTypeId) => {
  return httpClient.get(`/expedia/products/${propertyId}/roomTypes/${roomTypeId}/ratePlans`).catch(apiErrorHandler);
};

export const postCloseOpenRoomType = body => {
  return httpClient.post(`/expedia/closeOpenRoomType`, body).catch(apiErrorHandler);
};

export const postInventory = body => {
  return httpClient.post(`/expedia/inventory`, body).catch(apiErrorHandler);
};

export const postCloseOpenRate = body => {
  return httpClient.post(`/expedia/closeOpenRate`, body).catch(apiErrorHandler);
};

export const postRestrictions = body => {
  return httpClient.post(`/expedia/restrictions`, body).catch(apiErrorHandler);
};

export const postExpedia = expediaDetails => {
  return httpClient.post(`/expedia/`, expediaDetails).catch(apiErrorHandler);
};

export const putExpedia = (expediaIntegrationId, expediaDetails) => {
  return httpClient.put(`/expedia/${expediaIntegrationId}`, expediaDetails).catch(apiErrorHandler);
};

export const syncExpediaARI = body => {
  return httpClient.post(`/expedia/syncExpedia-ari`, body).catch(apiErrorHandler);
};

export const unsyncExpediaHotel = expediaId =>
  httpClient
    .delete('/expedia/' + expediaId)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getExpedias = async expediaId => {
  let uri = '/expedia';
  if (expediaId) {
    uri = '/expedia/' + expediaId;
  }
  return httpClient.get(uri).catch(apiErrorHandler);
};

export const getExpediaProduct = expediaPropertyId => {
  return httpClient.get(`/expedia/get-product?propertyId=${expediaPropertyId}`).catch(apiErrorHandler);
};

export const updateExpediaHost = (expediaId, hostId) => {
  return httpClient.put(`/expedia/update-host/${expediaId}`, { host: hostId }).catch(apiErrorHandler);
};

export const getExpediaMinMaxRate = roomTypeId => {
  return httpClient
    .get(`/expedia/roomTypes/${roomTypeId}/rateThresholds`)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getBookingcoms = () => {
  let uri = '/bookingcom';
  return httpClient.get(uri).catch(apiErrorHandler);
};

export const getBookingcomProduct = (bookingcomPropertyId, host) => {
  return httpClient.post(`/bookingcom/get-product?propertyId=${bookingcomPropertyId}${host ? `&host=${host}` : ``}`).catch(apiErrorHandler);
};

export const updateBookingcomHost = (bookingcomId, hostId) => {
  return httpClient
    .put(`/bookingcom/update-host/${bookingcomId}`, {
      host: hostId
    })
    .catch(apiErrorHandler);
};

export const syncBookingcomARI = body => {
  return httpClient.post(`/bookingcom/set-ari`, body).catch(apiErrorHandler);
};

export const unsyncBookingcomHotel = propertyId => {
  let body = {
    propertyId
  };
  return httpClient.post(`/bookingcom/unsync-bookingcom`, body).catch(apiErrorHandler);
};

export const putBookingcom = bookingcomDetails => {
  return httpClient.post(`/bookingcom/sync-bookingcom`, bookingcomDetails).catch(apiErrorHandler);
};
export const pushBookingcom = (propertyId, bookingcomDetails, legalEntity) => {
  return httpClient
    .post(`/bookingcom/createHotel/property/${propertyId}${legalEntity ? `?legalEntity=${legalEntity}` : ``}`, bookingcomDetails)
    .catch(apiErrorHandler);
};

export const getBookingcomRoomTypeNames = bookingcomRoomTypecode => {
  return httpClient
    .get(`/bookingcom/get-roomTypeName?bookingcomRoomTypecode=${bookingcomRoomTypecode}`)
    .then(res => res.data)
    .catch(apiErrorHandler);
};

/* ==========================================CTrip CM========================================== */

export const getCtripListing = (ctripListingId, ignoreSynced = true) => {
  return httpClient
    .get(`/ctripV2/getListing?ctripListingId=${ctripListingId}&&ignoreSynced=${ignoreSynced}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getCtripListings = () => {
  return httpClient
    .get('/ctripv2')
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const pullNewCtripListing = ctripListingId => {
  return httpClient
    .post(`/ctripv2/pullListing?ctripListingId=${ctripListingId}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const putCtripListing = (ctripId, updateListingPayload) => {
  return httpClient
    .put(`/ctripV2/update-host/${ctripId}`, updateListingPayload)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const syncNewCtrip = ctripListingPayload => {
  return httpClient
    .post(`/ctripV2/sync`, ctripListingPayload)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const syncUpdateCtrip = ctripListingPayload => {
  return httpClient
    .put(`/ctripV2/sync`, ctripListingPayload)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const unSyncCtripCMHotel = ctripListingId => {
  return httpClient
    .delete(`/ctripV2/sync/?ctripListingId=${ctripListingId}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

/* ==========================================Traveloka========================================== */
export const createPullTraveloka = travelokaPropertyId => {
  return httpClient
    .post(`/${TRAVELOKA_ROUTE}/pull`, {}, { params: { travelokaPropertyId } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getTraveloka = (travelokaId, { fields } = {}) => {
  return httpClient
    .get(`/${TRAVELOKA_ROUTE}/${travelokaId}`, { params: { _fields: fields } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getTravelokas = ({ query, fields } = {}) => {
  return httpClient
    .get(`/${TRAVELOKA_ROUTE}`, { params: { ...query, _fields: fields } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const putSyncTraveloka = (travelokaId, payload) =>
  httpClient
    .put(`${TRAVELOKA_ROUTE}/${travelokaId}/sync`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUnsyncTraveloka = travelokaId =>
  httpClient
    .put(`${TRAVELOKA_ROUTE}/${travelokaId}/unsync`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateTraveloka = (travelokaId, updateTravelokaPayload) =>
  httpClient
    .put(`/${TRAVELOKA_ROUTE}/${travelokaId}`, updateTravelokaPayload)
    .then(response => response.data)
    .catch(apiErrorHandler);

/* ==========================================Tiketcom========================================== */
export const createPullTiketcom = tiketcomPropertyId => {
  return httpClient
    .post(`/${TIKETCOM_ROUTE}/pull`, {}, { params: { tiketcomPropertyId } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getTiketcom = (tiketcomId, { fields } = {}) => {
  return httpClient
    .get(`/${TIKETCOM_ROUTE}/${tiketcomId}`, { params: { _fields: fields } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getTiketcoms = ({ query, fields } = {}) => {
  return httpClient
    .get(`/${TIKETCOM_ROUTE}`, { params: { ...query, _fields: fields } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const putSyncTiketcom = (tiketcomId, payload) =>
  httpClient
    .put(`${TIKETCOM_ROUTE}/${tiketcomId}/sync`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUnsyncTiketcom = tiketcomId =>
  httpClient
    .put(`${TIKETCOM_ROUTE}/${tiketcomId}/unsync`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateTiketcom = (tiketcomId, updateTiketcomPayload) =>
  httpClient
    .put(`/${TIKETCOM_ROUTE}/${tiketcomId}`, updateTiketcomPayload)
    .then(response => response.data)
    .catch(apiErrorHandler);
