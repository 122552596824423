import React, { useMemo, useState } from 'react';
import { Tabs, Modal } from 'antd';
import PropTypes from 'prop-types';

import OTALogo from 'components/OTALogo/OTALogo';

import AgodaHomesForm from './components/AgodaHomes/AgodaHomesForm';
import AirbnbForm from './components/Airbnb/AirbnbForm';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;

const useSelectedTabDetails = selectedIntegrationSource => {
  const [selectedTab, setSelectedTab] = useState(selectedIntegrationSource);

  const handleOnChangeTab = integrationSourceCode => {
    setSelectedTab(integrationSourceCode);
  };

  return { selectedTab, handleOnChangeTab };
};

const OTAListingForm = ({
  history,
  integrationSourcesConstant,
  listingIntegrations,
  selectedIntegrationSourceCode,
  propertyId,
  propertyHostId,
  unit,
  onClose,
  checkIsAdminReadOnly
}) => {
  const { selectedTab, handleOnChangeTab } = useSelectedTabDetails(selectedIntegrationSourceCode || integrationSourcesConstant.AIRBNB.code);
  const selectedListingIntegration = useMemo(() => listingIntegrations.find(integration => integration.source === selectedTab), [
    listingIntegrations,
    selectedTab
  ]);

  return (
    <Modal
      visible={true}
      title={`${intl.get('hostConnect.integration.headerLabels.otaIntegrationUnit').d('OTA Integration for Unit')} ${unit.name || ''}`}
      maskClosable={false}
      footer={null}
      onCancel={() => onClose(false)}
      width="60%"
      centered
      bodyStyle={{ height: '75vh' }}
    >
      <Tabs activeKey={selectedTab} onChange={handleOnChangeTab}>
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.AIRBNB.code} text={integrationSourcesConstant.AIRBNB.label} />}
          key={integrationSourcesConstant.AIRBNB.code}
        >
          <AirbnbForm
            history={history}
            propertyHostId={propertyHostId}
            listingIntegration={selectedListingIntegration}
            unit={unit}
            propertyId={propertyId}
            onClose={() => onClose(false)}
            onAfterSave={() => onClose(true)}
            checkIsAdminReadOnly={checkIsAdminReadOnly}
          />
        </TabPane>
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.AGODAHOMES.code} text={integrationSourcesConstant.AGODAHOMES.label} />}
          key={integrationSourcesConstant.AGODAHOMES.code}
        >
          <AgodaHomesForm
            listingIntegration={selectedListingIntegration}
            propertyHostId={propertyHostId}
            unit={unit}
            onAfterSave={() => onClose(true)}
            onClose={() => onClose(false)}
            checkIsAdminReadOnly={checkIsAdminReadOnly}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

OTAListingForm.propTypes = {
  history: PropTypes.object.isRequired,
  integrationSourcesConstant: PropTypes.object.isRequired,
  listingIntegrations: PropTypes.array,
  selectedIntegrationSourceCode: PropTypes.string,
  propertyId: PropTypes.string.isRequired,
  propertyHostId: PropTypes.string.isRequired,
  unit: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

OTAListingForm.defaultProps = {
  listingIntegrations: [],
  selectedIntegrationSourceCode: undefined,
  unit: {},
  onClose: () => {}
};

export default OTAListingForm;
